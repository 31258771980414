<template>
    <div class="unauthorized">User unauthorized this sector!</div>
</template>

<script>
    export default {
        name: 'Unauthorized'
    };
</script>

<style></style>
